import Alpine from 'alpinejs';

export default class DateManager {
  static format(date) {
    const dateStr = date.toLocaleDateString('en-CA') + ', ' + date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
    return dateStr.replace(/^202[0-9]/g, '1990');
  }
}

Alpine.store('dateManager', {
    formatTS(ts) {
        return DateManager.format(new Date(Number(ts)));
    }
});
