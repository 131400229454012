import Alpine from 'alpinejs';
import { nanoid } from 'nanoid';

import DateManager from '../controller/date-manager';

import './window-frame';
import { FileBrowser, FileBrowserTemplate } from './file-browser';

import { DSMail, DSMailTemplate } from './ds-mail';

import SVGMoon from '../assets/icon-moon.svg';
import SVGDefault from '../assets/desktop-default.svg';
import SVGFolder from '../assets/desktop-folder.svg';
import SVGMail from '../assets/desktop-mail.svg';

import './desktop.css';
import DesktopTemplate from './desktop.html';

Alpine.store('windowManager', {
    windows: [],
    open(params, id = nanoid()) {
      if (this.windows.some((w) => w.id === id)) return;
      this.windows.push({id, params});
    },
    close(id) {
      this.windows = this.windows.filter((w) => w.id !== id);
    }
});

Alpine.data('Desktop', () => ({
  DesktopTemplate,

  icons: {
    SVGMoon,
    SVGDefault,
    SVGFolder,
    SVGMail
  },

  desktopItems: [
    {id: 'templates', title: 'Document Templates', icon: 'SVGFolder', minWidth: '800px', minHeight: '250px', slotData: () => FileBrowser({path: '/templates'}), slotTemplate: FileBrowserTemplate},
    {id: 'email', title: 'Electronic Mail', icon: 'SVGMail', minWidth: '980px', minHeight: '600px', slotData: () => DSMail(), slotTemplate: DSMailTemplate}
  ],

  dateTime: '',
  updateDateTime() {
    this.dateTime = DateManager.format(new Date());
  },

  init() {
    setInterval(() => this.updateDateTime(), 10000);
    this.updateDateTime();
  }
}));
