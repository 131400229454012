import Alpine from 'alpinejs';

import '../controller/date-manager';

import './list-view';

import SVGInbox from '../assets/mail-inbox.svg';
import SVGOutbox from '../assets/mail-outbox.svg';
import SVGSpam from '../assets/mail-spam.svg';
import SVGTrash from '../assets/mail-trash.svg';
import SVGReply from '../assets/mail-reply.svg';

import './ds-mail.css';
import DSMailTemplate from './ds-mail.html';

export { DSMailTemplate };

export const DSMail = (state) => ({
  DSMailTemplate,

  selectedBox: 'inbox',

  icons: {
    SVGInbox,
    SVGOutbox,
    SVGSpam,
    SVGTrash,
    SVGReply
  },

  demoData: [
    {from: 'bishop@atlaslaw.rp', to: 'max@admin.rp', subject: 'Re: Bus Incident', created: '1703906364403', content: 'FFS'},
    {from: 'interns@courthouse.rp', to: 'max@admin.rp', subject: 'Re: Contract', created: 1704907364403, content: ''}
  ]
});

Alpine.data('DSMail', DSMail);
