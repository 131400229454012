// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-editor {
  display: block;
  position: relative;

  padding: 10px;
  min-width: 300px;
  min-height: 100px;

  background: #fff;
}

.document-editor .codex-editor .ce-paragraph {
  font-family: 'Geneva', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.01em;
}
`, "",{"version":3,"sources":["webpack://./src/component/document-editor.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;;EAElB,aAAa;EACb,gBAAgB;EAChB,iBAAiB;;EAEjB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".document-editor {\n  display: block;\n  position: relative;\n\n  padding: 10px;\n  min-width: 300px;\n  min-height: 100px;\n\n  background: #fff;\n}\n\n.document-editor .codex-editor .ce-paragraph {\n  font-family: 'Geneva', sans-serif;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 1.3;\n  letter-spacing: 0.01em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
