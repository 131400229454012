import Alpine from 'alpinejs';

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from "@editorjs/list";
import Checklist from '@editorjs/checklist';
import Quote from '@editorjs/quote';

import './document-editor.css';
import DocumentEditorTemplate from './document-editor.html';

export { DocumentEditorTemplate };

export const DocumentEditor = (state) => ({
  DocumentEditorTemplate,

  init() {
    this.$nextTick(() => {
      this.editor = new EditorJS({
        holder: this.$refs.editorContainer,
        minHeight: 0,
        data: state.content,
        tools: {
          header: Header,
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered'
            }
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          quote: Quote
        }
      });
    });
  },

  destroy() {
    console.log('asdf');
    this.editor.destroy();
  },

  async saveData() {
    const data = await this.editor.save();
    console.log(data);
  }
});

Alpine.data('DocumentEditor', DocumentEditor);
