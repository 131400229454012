import Alpine from 'alpinejs';

import '../controller/z-manager';

import './window-frame.css';
import WindowFrameTemplate from './window-frame.html';

export { WindowFrameTemplate };

export const WindowFrame = (state, id) => ({
  WindowFrameTemplate,

  // slotNode: null,
  slotData: state.slotData,
  slotTemplate: state.slotTemplate,

  id: id,

  title: state.title,

  minWidth: state.minWidth || '256px',
  minHeight: state.minHeight || '128px',

  useInputCatcher: state.useInputCatcher,

  isMinimized: false,
  isMaximized: false,

  startPos: null,
  stopPos: {x: 50, y: 50},

  transform: 'translate(50px, 50px)',

  zIndex: 0,

  init() {
    // this.$el.firstElementChild?.removeAttribute?.('x-ignore');
    // this.slotNode = this.$el.firstElementChild?.outerHTML || '';

    this.$nextTick(() => {
      this.zNext();
      this.windowBounds = this.$refs.windowFrame.getBoundingClientRect();
    });
  },

  destroy() {
    console.log('windowFrame destroy: ' + this.id);
  },

  close() {
    this.$store.windowManager.close(this.id);
  },

  mouseEvent(evt) {
    const eventPos = {x: evt.pageX, y: evt.pageY};

    if (evt.type === 'mousedown' && evt.button === 0 && evt.target === this.$refs.frameTitleBar) {
      if (this.isMaximized) {
        if (this.stopPos) this.stopPos.y = 0;
      } else {
        this.windowBounds = this.$refs.windowFrame.getBoundingClientRect();
      }

      this.containerBounds = document.getElementById('window-container').getBoundingClientRect();

      this.startPos = this.stopPos ? {x: eventPos.x - this.stopPos.x, y: eventPos.y - this.stopPos.y} : eventPos;

      this.eventWrapper = this.mouseEvent.bind(this);
      document.addEventListener('mousemove', this.eventWrapper);
    }

    if (!this.startPos) return true;

    const windowPos = {x: eventPos.x - this.startPos.x, y: eventPos.y - this.startPos.y};

    if (windowPos.x < 0) windowPos.x = 0;
    if (windowPos.y < 0) windowPos.y = 0;
    if (windowPos.x + this.windowBounds.width > this.containerBounds.width) windowPos.x = this.containerBounds.width - this.windowBounds.width;
    if (windowPos.y + this.windowBounds.height > this.containerBounds.height) windowPos.y = this.containerBounds.height - this.windowBounds.height;

    if (evt.type === 'mousemove' && this.isMaximized) this.isMaximized = false;

    if (evt.type === 'mouseup' || evt.type === 'mouseleave') {
      this.stopPos = windowPos;
      this.startPos = null;

      document.removeEventListener('mousemove', this.eventWrapper);
      return true;
    }

    this.transform = `translate(${windowPos.x}px, ${windowPos.y}px)`;
  },

  toggleMinimized() {
    this.isMinimized = !this.isMinimized;
    this.isMaximized = false;
  },

  toggleMaximized() {
    this.isMaximized = !this.isMaximized;
    this.isMinimized = false;
  },

  zNext() {
    this.zIndex = this.$store.zManager.next();
  }
});

Alpine.data('WindowFrame', WindowFrame);
