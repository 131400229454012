import Alpine from 'alpinejs';

import { ListView, ListViewTemplate } from './list-view';
import { DocumentEditor, DocumentEditorTemplate } from './document-editor';

import './file-browser.css';
import FileBrowserTemplate from './file-browser.html';

export { FileBrowserTemplate };

export const FileBrowser = (state) => ({
  FileBrowserTemplate,

  items: [
    {id: 'doc1', title: 'Service Agreement Template', created: 1703906364403, type: 'Document', content: {
      "time": 1705039914661,
      "blocks": [
        {
          "id": "8lsRo-BrEO",
          "type": "paragraph",
          "data": {
            "text": "This Service Agreement (\"Agreement\") is made and entered into effective as of [date] between the following parties:"
          }
        },
        {
          "id": "GIuYgQB-JD",
          "type": "paragraph",
          "data": {
            "text": "Party 1: [Company/Individual Name, Address, Contact Information]"
          }
        },
        {
          "id": "4MHECH3QMi",
          "type": "paragraph",
          "data": {
            "text": "Party 2: [Contractor/Independent Contractor Name, Address, Contact information]"
          }
        },
        {
          "id": "3Cir2q3MdT",
          "type": "paragraph",
          "data": {
            "text": "WHEREAS Party 1 requires certain services to be performed for its business; and"
          }
        },
        {
          "id": "5F903RJLZq",
          "type": "paragraph",
          "data": {
            "text": "WHEREAS Party 2 has agreed to perform such Services on behalf of Party 1."
          }
        },
        {
          "id": "A9IlVidiMO",
          "type": "paragraph",
          "data": {
            "text": "NOW THEREFORE, in consideration of the mutual promises contained herein, the parties agree as follows:"
          }
        },
        {
          "id": "n0kANQESk1",
          "type": "paragraph",
          "data": {
            "text": "1. Services"
          }
        },
        {
          "id": "_sBL0TRrS3",
          "type": "paragraph",
          "data": {
            "text": "Party 2 shall provide the following services (\"the Services\") to Party 1: [Describe the specific tasks or services that will be performed by Contractor/Independent Contractor]."
          }
        },
        {
          "id": "FBipirGe8M",
          "type": "paragraph",
          "data": {
            "text": "2. Compensation and Payments"
          }
        },
        {
          "id": "NVcKMtYdQ3",
          "type": "paragraph",
          "data": {
            "text": "In exchange for the provision of the Services, Party 1 agrees to pay Party 2 a total sum of $[Amount in USD] (the \"Compensation\") as described below:"
          }
        },
        {
          "id": "xpU3qfwkJT",
          "type": "paragraph",
          "data": {
            "text": "- [Describe how compensation is calculated or determined based on hours worked or project milestones.]"
          }
        },
        {
          "id": "Y20xNjaUq5",
          "type": "paragraph",
          "data": {
            "text": "- The Compensation shall be payable within [amount of days/weeks] after completion of the Services."
          }
        },
        {
          "id": "b6ZjdATih8",
          "type": "paragraph",
          "data": {
            "text": "3. Term and Termination"
          }
        },
        {
          "id": "oiSe8OGPCD",
          "type": "paragraph",
          "data": {
            "text": "The term (the \"Term\") of this Agreement will commence as of [date], and continue until either party gives 14 days' written notice to terminate it."
          }
        },
        {
          "id": "0twlOfYzwd",
          "type": "paragraph",
          "data": {
            "text": "4. Representations and Warranties"
          }
        },
        {
          "id": "tJzzpxeEkE",
          "type": "paragraph",
          "data": {
            "text": "Each party represents and warrants that:"
          }
        },
        {
          "id": "dLAHCS2MRt",
          "type": "paragraph",
          "data": {
            "text": "- They have the power and authority to enter into and perform their obligations under this Agreement;"
          }
        },
        {
          "id": "GSpHz0KFK3",
          "type": "paragraph",
          "data": {
            "text": "- This Agreement does not violate any other agreement, bylaw or law they may be subject to; and"
          }
        },
        {
          "id": "dPWazKJTIg",
          "type": "paragraph",
          "data": {
            "text": "- The Services will not infringe upon any patent, trademark, trade secret, copyright or other proprietary right of any third party."
          }
        },
        {
          "id": "3PHkyXtVsr",
          "type": "paragraph",
          "data": {
            "text": "5. Confidentiality"
          }
        },
        {
          "id": "TAgbUcNSnL",
          "type": "paragraph",
          "data": {
            "text": "During the Term, Party 2 shall maintain in strict confidence and not disclose to others, except as required by law, any information regarding the business, operations, or affairs of Party 1 that is provided during the performance of the Services."
          }
        },
        {
          "id": "gWG2Ka3POg",
          "type": "paragraph",
          "data": {
            "text": "6. Independent Contractor Status"
          }
        },
        {
          "id": "Auu_Q8aXLt",
          "type": "paragraph",
          "data": {
            "text": "Party 2's relationship to Party 1 will be that of an independent contractor. Nothing contained in this Agreement shall create a partnership, joint venture, employment relationship, franchise, agency, or other form of legal association between the parties and neither party is authorized to bind the other in any respect whatsoever."
          }
        },
        {
          "id": "raRIAKYU_D",
          "type": "paragraph",
          "data": {
            "text": "7. Governing Law and Jurisdiction"
          }
        },
        {
          "id": "nLsiL8vjWZ",
          "type": "paragraph",
          "data": {
            "text": "This Agreement will be governed by and construed in accordance with the laws of [insert jurisdiction] without giving effect to any conflict-of-law rules that may provide for the application of the law of another jurisdiction. Any disputes arising out of or relating to this Agreement shall be resolved exclusively in the courts located in [insert jurisdiction]."
          }
        },
        {
          "id": "hEV-ORDZRA",
          "type": "paragraph",
          "data": {
            "text": "8. Miscellaneous"
          }
        },
        {
          "id": "CDvIExycK5",
          "type": "paragraph",
          "data": {
            "text": "This Agreement constitutes the entire agreement between the parties and supersedes any previous or contemporaneous agreements relating to its subject matter. No amendment or modification of this Agreement will be effective unless it is made in writing and signed by both parties."
          }
        },
        {
          "id": "-3rpbZiM-Y",
          "type": "paragraph",
          "data": {
            "text": "IN WITNESS WHEREOF, the Parties have executed this Service Agreement as of [date]."
          }
        },
        {
          "id": "PVE3dI5_NY",
          "type": "paragraph",
          "data": {
            "text": "Party 1:"
          }
        },
        {
          "id": "z4WReBoqOh",
          "type": "paragraph",
          "data": {
            "text": "[Company/Individual Name, Address, Contact Information]"
          }
        },
        {
          "id": "LDoyjW5ibF",
          "type": "paragraph",
          "data": {
            "text": "Authorized Signatory:"
          }
        },
        {
          "id": "R-_KBRTja7",
          "type": "paragraph",
          "data": {
            "text": "_______________________________"
          }
        },
        {
          "id": "UnXPPRZrng",
          "type": "paragraph",
          "data": {
            "text": "Title: ______________________________"
          }
        },
        {
          "id": "iZ_QPPW6GV",
          "type": "paragraph",
          "data": {
            "text": "Date: _____________________________"
          }
        }
      ],
      "version": "2.28.2"
    }},
    {id: 'doc2', title: 'Another Doc But Better', created: 1703906374612, type: 'Better Document', content: {
      "time": 1705039549564,
      "blocks": [
        {
          "id": "pMnuHUqobI",
          "type": "paragraph",
          "data": {
            "text": "It's not actually better."
          }
        }
      ],
      "version": "2.28.2"
    }}
  ],
  openItem(item) {
    this.$store.windowManager.open({id: item.id, title: item.title, minWidth: '770px', minHeight: '770px', slotData: () => DocumentEditor({content: item.content}), slotTemplate: DocumentEditorTemplate}, item.id);
  }
});

Alpine.data('FileBrowser', FileBrowser);
