// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-list-view {
  width: 100%;
}

.ds-list-view th {
  text-align: left;
  height: 32px;
}

.ds-list-view tr {
  background: #fff;
}

.ds-list-view th,
.ds-list-view td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-width: 0px;

  padding: 2px;
}

.ds-list-view th.col-selected {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.ds-list-view .list-icon {
  width: 48px;
  max-width: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/component/list-view.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;;EAEhB,cAAc;;EAEd,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".ds-list-view {\n  width: 100%;\n}\n\n.ds-list-view th {\n  text-align: left;\n  height: 32px;\n}\n\n.ds-list-view tr {\n  background: #fff;\n}\n\n.ds-list-view th,\n.ds-list-view td {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n\n  max-width: 0px;\n\n  padding: 2px;\n}\n\n.ds-list-view th.col-selected {\n  text-decoration-line: underline;\n  text-decoration-thickness: 2px;\n}\n\n.ds-list-view .list-icon {\n  width: 48px;\n  max-width: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
