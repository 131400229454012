// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-mail {
  position: relative;
  width: 100%;
  height: 100%;
}

.ds-mail .mail-sidebar > * {
  background: #fff;
  padding: 3px 6px;
}

.ds-mail .mail-sidebar svg {
  width: 24px;
  vertical-align: -5px;
}

.ds-mail .mail-toolbar svg {
  width: 42px;
}

.ds-mail .mail-message-content h4 {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/component/ds-mail.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".ds-mail {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.ds-mail .mail-sidebar > * {\n  background: #fff;\n  padding: 3px 6px;\n}\n\n.ds-mail .mail-sidebar svg {\n  width: 24px;\n  vertical-align: -5px;\n}\n\n.ds-mail .mail-toolbar svg {\n  width: 42px;\n}\n\n.ds-mail .mail-message-content h4 {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
