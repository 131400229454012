import Alpine from 'alpinejs';

import '../controller/date-manager';

import SVGDocument from '../assets/desktop-document.svg';

import './list-view.css';
import ListViewTemplate from './list-view.html';

export { ListViewTemplate };

export const ListView = (state) => ({
  ListViewTemplate,

  icons: {
    SVGDocument
  },

  items: state.items,
  columns: state.columns,
  hideIcon: state.hideIcon,

  openItem: state.openItem,

  selectedCol: state.columns[state.columns.length - 1].key,
  selectedRow: -1
});

Alpine.data('ListView', ListView);
